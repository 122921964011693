import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import DropFileUploader from '../../../../../components/Form/DropFileUploader'
import DropFileUploaderProgress from '../../../../../components/Form/DropFileUploader/DropFileUploaderProgress'

import { showToasts } from '../../../../../helpers/toasts'
import { csvFileValidationHandler } from './helpers'

import { bulkUploadFootfallData, clearBulkUploadFootfallData } from '../../../../../modules/actions/footfallData'
import {
  bulkUploadFootfallDataErrorSelector,
  bulkUploadFootfallDataIsLoadingSelector,
  bulkUploadFootfallDataWasCreatedSelector
} from '../../../../../modules/selectors/footfallData'
import { fileUploadProgressSelector } from '../../../../../modules/selectors/files'
import { updateFileUploadProgress } from '../../../../../modules/actions/files'

import { TOAST_TYPE } from '../../../../../constants/other'

// This constant is used to track the progress footfall data file upload
export const FOOTFALL_DATA_CSV_FILE = 'footfall_data_csv_file'

const FootfallDataFileUpload = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const bulkUploadFootfallDataWasLoaded = useSelector(bulkUploadFootfallDataWasCreatedSelector)
  const bulkUploadFootfallDataIsLoading = useSelector(bulkUploadFootfallDataIsLoadingSelector)
  const bulkUploadFootfallDataError = useSelector(bulkUploadFootfallDataErrorSelector)

  const { [FOOTFALL_DATA_CSV_FILE]: fileUploadProgress } = useSelector(fileUploadProgressSelector)

  const submitHandler = useCallback(
    file => {
      dispatch(bulkUploadFootfallData({ file }))
    },
    [dispatch]
  )

  const handleFileChange = useCallback(
    file => {
      // submitHandler(file)
      csvFileValidationHandler(file)
        .then(() => submitHandler(file))
        .catch(error => {
          showToasts({
            type: TOAST_TYPE.error,
            message: error.message
          })
        })
    },
    [submitHandler]
  )

  const clearHandler = useCallback(() => {
    dispatch(clearBulkUploadFootfallData())
    // reset progress of the footfall data file upload
    dispatch(updateFileUploadProgress({ [FOOTFALL_DATA_CSV_FILE]: 0 }))
  }, [dispatch])

  useEffect(() => {
    if (bulkUploadFootfallDataError) {
      const fileError = bulkUploadFootfallDataError?.errors?.file?.[0]
      const defaultMessage = t('An error occurred while uploading footfall data')
      const errorMessage = fileError ? fileError : defaultMessage

      showToasts({
        type: TOAST_TYPE.error,
        message: t(errorMessage)
      })
      clearHandler()
    }
  }, [t, clearHandler, bulkUploadFootfallDataError])

  useEffect(() => {
    if (bulkUploadFootfallDataWasLoaded) {
      showToasts({
        type: TOAST_TYPE.success,
        message: t('Footfall data was uploaded')
      })
      clearHandler()
    }
  }, [t, clearHandler, bulkUploadFootfallDataWasLoaded])

  if (bulkUploadFootfallDataIsLoading) {
    return <DropFileUploaderProgress fileUploadProgress={fileUploadProgress} />
  }

  return <DropFileUploader accept="text/csv" onUpload={handleFileChange} />
}

export default FootfallDataFileUpload
