import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Dropdown from '../../../components/Dropdown'
import Button from '../../../components/Button'
import { MobileDown } from '../../../components/hoc/ResponsiveRendering'
import ButtonIcon from '../Buttons/ButtonIcon'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron-arrow-down.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg'
import SpoilerFilter from '../../../components/SpoilerFilter'

import { isFilterSelected, sortFiltersByUsage } from './helpers'

import useStyles from './styles'

const allowedPlacements = ['bottom-end']

const DropdownFilters = ({ filters, Context, onInitialOpen }) => {
  const { t } = useTranslation()

  const [wasFilterOpened, setWasFilterOpened] = useState(false)
  const [isFiltersOpen, setFiltersOpen] = useState(false)

  const classes = useStyles({ isOpen: isFiltersOpen })

  const { filtersState, setFiltersState, resetValues } = useContext(Context)

  const alphabeticallySortedFilters = useMemo(() => {
    return [...filters].sort((a, b) => a.title.localeCompare(b.title))
  }, [filters])

  // filtersSortedByUsage is stored in state because we don't need to move filters to the top when user applies a filter
  //  We only need to move filters to the top when the dropdown is closed.
  const [filtersSortedByUsage, setFiltersSortedByUsage] = useState(
    sortFiltersByUsage(filtersState, alphabeticallySortedFilters)
  )

  const closeFiltersHandler = useCallback(() => {
    setFiltersOpen(false)
  }, [])

  const resetHandler = useCallback(
    filterName => {
      const resetValue = resetValues?.hasOwnProperty(filterName)
        ? // Some filters may have custom reset values i.e date range filter, use it if it's provided
          resetValues[filterName]
        : // Otherwise reset to empty array or empty string
          Array.isArray(filtersState[filterName])
          ? []
          : ''

      const updatedFiltersState = {
        ...filtersState,
        [filterName]: resetValue
      }

      setFiltersState(updatedFiltersState)
    },
    [filtersState, setFiltersState, resetValues]
  )

  const onOpenChange = useCallback(
    isOpen => {
      setFiltersOpen(isOpen)

      // handle initial open
      if (!wasFilterOpened && isOpen) {
        onInitialOpen && onInitialOpen()
        setWasFilterOpened(true)
      }

      if (!isOpen) {
        // When the dropdown is closed, sort filters by usage
        setFiltersSortedByUsage(sortFiltersByUsage(filtersState, alphabeticallySortedFilters))
      }
    },
    [
      filtersState,
      alphabeticallySortedFilters,
      setFiltersOpen,
      setFiltersSortedByUsage,
      onInitialOpen,
      wasFilterOpened,
      setWasFilterOpened
    ]
  )

  return (
    <Dropdown
      isOpen={isFiltersOpen}
      onOpenChange={onOpenChange}
      allowPlacementFlip={false}
      className={classes.filters}
      offsetSize={5}
      allowedPlacements={allowedPlacements}
      strategy="absolute"
      triggerElement={
        <Button type="button" className={classes.filtersButton}>
          {t('Filters')}
          <ArrowIcon className={classes.arrowIcon} />
        </Button>
      }
      // We need to render children when the dropdown is closed to not lose the state of the filters
      renderChildrenWhenClosed={wasFilterOpened}
    >
      <div className={classes.filtersHeader}>
        <span className={classes.filtersTitle}>{t('Filters')}</span>
        <MobileDown>
          <ButtonIcon className={classes.closeFiltersButton} onClick={closeFiltersHandler} Icon={CloseIcon} />
        </MobileDown>
      </div>
      {filtersSortedByUsage.map((filter, index) => {
        const isSelected = isFilterSelected(filtersState, filter)

        return (
          <SpoilerFilter
            key={filter.filterName}
            initialOpen={isSelected}
            title={filter.title}
            allowReset={filter.allowReset}
            onReset={() => resetHandler(filter.filterName)}
            allowMinimize={!isSelected}
          >
            {filter.component}
          </SpoilerFilter>
        )
      })}
    </Dropdown>
  )
}

DropdownFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      filterName: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      show: PropTypes.bool
    })
  ).isRequired,
  Context: PropTypes.object.isRequired,
  onInitialOpen: PropTypes.func
}

export default DropdownFilters
