import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

export const useFormatGuidelinesColumns = () => {
  const { t } = useTranslation()

  const classes = useStyles()
  return useMemo(
    () => [
      {
        header: () => (
          <div>
            {t('Area')} <span className={classes.regularWeight}>{t('or')}</span> {t('Store')}
          </div>
        ),
        Cell: () => (
          <div>
            {t('The external Area ID')}
            <br />
            {t('or')}
            <br />
            {t('The store ID')}
          </div>
        )
      },
      {
        header: 'Date',
        Cell: () => (
          <div>
            {t('The start date of the period in one of the following formats:')}
            <br />
            YYYY-MM-DD or DD/MM/YYYY
          </div>
        )
      },
      {
        header: 'Reach',
        Cell: 'The footfall figure for the period'
      },
      {
        header: 'Period Type',
        Cell: 'One of the following: daily, weekly, fortnightly'
      }
    ],
    [t, classes]
  )
}
