const internalColumns = ['Store', 'Date', 'Reach', 'Period type']
const externalColumns = ['Area', 'Date', 'Reach', 'Period type']

const allowedHeaderSets = [internalColumns, externalColumns]

export const csvFileValidationHandler = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = event => {
      const content = event.target.result
      // Split into rows, handling various newline characters
      const rows = content.split(/\r\n|\n/)
      if (rows.length === 0 || !rows[0].trim()) {
        reject(new Error('CSV file is empty or missing its header row.'))
        return
      }

      // Get headers from the first row and trim whitespace
      const headers = rows[0].split(',').map(h => h.trim())

      // Check if header row includes all required columns for at least one allowed set
      const isValid = allowedHeaderSets.some(requiredSet =>
        requiredSet.every(requiredHeader => headers.includes(requiredHeader))
      )

      if (!isValid) {
        reject(new Error('The CSV file header is not valid.'))
      } else {
        resolve()
      }
    }

    reader.onerror = () => {
      reject(new Error('There was an error reading the CSV file.'))
    }

    reader.readAsText(file)
  })
}
