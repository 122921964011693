import { v4 as uuidv4 } from 'uuid'

import { IMAGES, ORDER } from '../../../../../../features/components/Form/SortableUploadImagesList/fields'
import {
  ADDRESS,
  ALT_TEXT,
  BOOKING_NOTIFICATION_RECIPIENTS,
  EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS,
  initialExternalRecipientValue,
  initialStoreValue,
  INTERNAL_ID,
  MEDIA_PRODUCT_LOCATION_CATEGORY,
  MEDIA_PRODUCT_LOCATION_REGION,
  NAME,
  SEND_BOOKING_SUMMARY_NOTIFICATION,
  STORES,
  SUMMARY_DAYS_ADVANCE,
  SUMMARY_INTERVAL
} from '../../fields'
import { initialImageFileValues } from '../../../../../../constants/files'

export const initialValues = {
  [NAME]: '',
  [ADDRESS]: '',
  [IMAGES]: [
    {
      // id is needed for unique key in the list
      id: uuidv4(),
      [ALT_TEXT]: '',
      [ORDER]: 1,
      ...initialImageFileValues
    }
  ],
  [BOOKING_NOTIFICATION_RECIPIENTS]: [],
  [EXTERNAL_BOOKING_NOTIFICATION_RECIPIENTS]: [{
    id: uuidv4(),
    ...initialExternalRecipientValue
  }],
  [SEND_BOOKING_SUMMARY_NOTIFICATION]: false,
  [SUMMARY_INTERVAL]: '',
  [SUMMARY_DAYS_ADVANCE]: 0,
  [STORES]: [{
    id: uuidv4(),
    ...initialStoreValue
  }],
  [INTERNAL_ID]: '',
  [MEDIA_PRODUCT_LOCATION_CATEGORY]: '',
  [MEDIA_PRODUCT_LOCATION_REGION]: ''
}
