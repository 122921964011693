import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import RepresentativeFilter, {
  MULTIPLE_REPRESENTATIVE_FILTER
} from '../../../../../../features/components/Filters/RepresentativeFilter'
import MultipleControllerSelfAccountsFilter, {
  MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER
} from '../../../../../../features/components/DropdownFilters/components/MultipleControllerSelfAccountsFilter'
import DropdownFilters from '../../../../../../features/components/DropdownFilters'
import LocationsFilter, {
  SELECTED_LOCATIONS
} from '../../../../../../features/components/DropdownFilters/components/LocationsFilter'
import CategoriesFilter, {
  SELECTED_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/CategoriesFilter'
import SubCategoriesFilter, {
  SELECTED_SUB_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/SubCategoriesFilter'
import { mediaSubCategoriesSelector } from '../../../../../../modules/selectors/mediaOrdersProducts'
import { getAllSubSubCategories } from '../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter/helpers'
import SubSubCategoriesFilter, {
  SELECTED_SUB_SUB_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/SubSubCategoriesFilter'
import ProductsFilter, {
  SELECTED_PRODUCTS
} from '../../../../../../features/components/DropdownFilters/components/ProductsFilter'
import {
  BRAND_CATEGORY,
  BrandCategory
} from '../../../../../../features/components/DropdownFilters/components/AccountCategoriesFilters'
import {
  BRAND_SUB_CATEGORY,
  BrandSubCategoryFilter
} from '../../../../../../features/components/DropdownFilters/components/BrandSubCategoryFilter'

import { selfAccountSubCategoriesSelector } from '../../../../../../modules/selectors/selfAccounts'
import { selectedControllerIdSelector, userSelfAccountTypeSelector } from '../../../../../../modules/selectors/app'
import {
  clearGetMediaSubCategories,
  getMediaSubCategories
} from '../../../../../../modules/actions/mediaOrdersProducts'
import {
  clearGetSelfAccountSubCategories,
  getSelfAccountSubCategories
} from '../../../../../../modules/actions/selfAccounts'

import {
  BookedMediaCalendarDataProvider,
  BookedMediaCalendarFiltersContext
} from '../../../../../ManagementReporting/BookedMediaReport/CalendarBookedMediaFilters/BookedMediaCalendarFiltersContext'
import { CONTROLLER_TYPE } from '../../../../../../constants/permissions'

import useStyles from './styles'
import LocationCategoriesFilter, {
  LOCATION_CATEGORIES
} from '../../../../../../features/components/DropdownFilters/components/LocationCategoriesFilter'
import LocationRegionsFilter, {
  LOCATION_REGIONS
} from '../../../../../../features/components/DropdownFilters/components/LocationRegionsFilter'
import {
  clearGetMediaProductLocationCategories,
  clearGetMediaProductLocationRegions,
  getMediaProductLocationCategories,
  getMediaProductLocationRegions
} from '../../../../../../modules/actions/mediaOrdersProductLocations'

const CalendarBookedMediaFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const controllerId = useSelector(selectedControllerIdSelector)

  const userAccountType = useSelector(userSelfAccountTypeSelector)
  const selfAccountSubCategories = useSelector(selfAccountSubCategoriesSelector)
  const mediaSubCategories = useSelector(mediaSubCategoriesSelector)
  const allSubSubCategories = useMemo(() => getAllSubSubCategories(mediaSubCategories), [mediaSubCategories])

  const filters = useMemo(() => {
    return [
      {
        component: <MultipleControllerSelfAccountsFilter Context={BookedMediaCalendarFiltersContext} />,
        title: 'Accounts',
        filterName: MULTIPLE_CONTROLLER_SELF_ACCOUNTS_FILTER,
        show: userAccountType === CONTROLLER_TYPE
      },
      {
        component: <RepresentativeFilter Context={BookedMediaCalendarFiltersContext} />,
        title: 'Representative',
        filterName: MULTIPLE_REPRESENTATIVE_FILTER,
        show: true
      },
      {
        component: <LocationsFilter Context={BookedMediaCalendarFiltersContext} placeholder="Locations" />,
        title: 'Locations',
        filterName: SELECTED_LOCATIONS,
        show: true
      },
      {
        component: (
          <LocationCategoriesFilter Context={BookedMediaCalendarFiltersContext} placeholder="Location Category" />
        ),
        title: 'Location Category',
        filterName: LOCATION_CATEGORIES,
        show: true
      },
      {
        component: <LocationRegionsFilter Context={BookedMediaCalendarFiltersContext} placeholder="Location Region" />,
        title: 'Location Region',
        filterName: LOCATION_REGIONS,
        show: true
      },
      {
        component: <CategoriesFilter Context={BookedMediaCalendarFiltersContext} />,
        title: 'Categories',
        filterName: SELECTED_CATEGORIES,
        show: true
      },
      {
        component: <SubCategoriesFilter Context={BookedMediaCalendarFiltersContext} />,
        title: 'Asset Type',
        filterName: SELECTED_SUB_CATEGORIES,
        show: !!mediaSubCategories.length
      },
      {
        component: <SubSubCategoriesFilter Context={BookedMediaCalendarFiltersContext} />,
        title: 'Asset Subtype',
        filterName: SELECTED_SUB_SUB_CATEGORIES,
        show: !!allSubSubCategories.length
      },
      {
        component: <ProductsFilter Context={BookedMediaCalendarFiltersContext} />,
        title: 'Products',
        filterName: SELECTED_PRODUCTS,
        show: true
      },
      {
        component: <BrandCategory Context={BookedMediaCalendarFiltersContext} />,
        title: 'Brand Category',
        filterName: BRAND_CATEGORY,
        show: true
      },
      {
        component: <BrandSubCategoryFilter Context={BookedMediaCalendarFiltersContext} />,
        title: 'Brand Sub Category',
        filterName: BRAND_SUB_CATEGORY,
        show: !!selfAccountSubCategories.length
      }
    ]
  }, [userAccountType, allSubSubCategories, mediaSubCategories, selfAccountSubCategories])

  const fetchOptionsHandler = useCallback(() => {
    dispatch(
      getMediaSubCategories({
        controller: controllerId
      })
    )
    dispatch(
      getSelfAccountSubCategories({
        controller: controllerId
      })
    )
    dispatch(
      getMediaProductLocationCategories({
        controller: controllerId,
        ordering: 'name',
        limit: 999
      })
    )
    dispatch(
      getMediaProductLocationRegions({
        controller: controllerId,
        ordering: 'name',
        limit: 999
      })
    )
  }, [dispatch, controllerId])

  useEffect(() => {
    return () => {
      dispatch(clearGetSelfAccountSubCategories())
      dispatch(clearGetMediaSubCategories())
      dispatch(clearGetMediaProductLocationRegions())
      dispatch(clearGetMediaProductLocationCategories())
    }
  }, [dispatch])

  return (
    <BookedMediaCalendarDataProvider onFiltersChange={onFiltersChange}>
      <div className={classes.filtersRow}>
        <div className={classes.rightFiltersRow}>
          <DropdownFilters
            filters={filters}
            Context={BookedMediaCalendarFiltersContext}
            onInitialOpen={fetchOptionsHandler}
          />

          {/*this is used for portalled filters - don't remove until check id usage */}
          <div id="booked_media_calendar_view_portal" />
          {/*this is used for pdf generation */}
          <div id="pdf_generation_portal" />
        </div>
      </div>
    </BookedMediaCalendarDataProvider>
  )
}

CalendarBookedMediaFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default CalendarBookedMediaFilters
